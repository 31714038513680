import React, { useEffect, useState } from "react";
import { api } from "../../api";
// import NoOfCertificatesChart from "./components/NoOfCertificatesChart";
import Select from "react-select";
import { BiUpArrowAlt } from "react-icons/bi";

function Dashboard() {
  const [dashBoardData, setDashBoardData] = useState<any>({});
  const [clientList, setClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedClientInfo, setSelectedClientInfo] = useState<any>(null);

  useEffect(() => {
    console.log("dashBoardData =>", dashBoardData);
    console.log("client list =>", clientList);
  }, [dashBoardData]);

  // get clients list
  useEffect(() => {
    api
      .post("/user/search", {
        role: "CLIENT_SELLER",
        page: 1,
        take: 300,
      })
      .then((response: any) => {
        // Filter out clients where client_name is empty or null, then map the rest
        const mappedClients = response?.data?.items
          ?.filter((client: any) => client?.client_name) // This filters out clients with empty or null names
          .map((client: any) => ({
            name: "client",
            value: client?.client_name,
            label: client?.client_name,
          }));

        setClientList(mappedClients);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // call /admin/dashboard/ endpoint
  useEffect(() => {
    getAdminDashboardData()
      .then((response: any) => {
        if (response) {
          setDashBoardData(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // function to get dashboard response
  const getAdminDashboardData = async () => {
    const response = await api.get("/admin/dashboard/");
    return response.data;
  };

  // registry updates sample list
  const registryUpdates = [
    {
      topic: "Evident Registry Guidelines update 2024",
      date: "22 Feb, 2024",
    },
    {
      topic: "Evident Registry Guidelines update 2024",
      date: "22 Feb, 2024",
    },
    {
      topic: "Evident Registry Guidelines update 2024",
      date: "22 Feb, 2024",
    },
    {
      topic: "Evident Registry Guidelines update 2024",
      date: "22 Feb, 2024",
    },
    {
      topic: "Evident Registry Guidelines update 2024",
      date: "22 Feb, 2024",
    },
    {
      topic: "Evident Registry Guidelines update 2024",
      date: "22 Feb, 2024",
    },
  ];

  // handle change client
  const handleChangeClient = (e: any) => {
    console.log("event =>", e);
    const selectedClient = dashBoardData.client_data[e.value];
    setSelectedClientInfo(selectedClient);
    setSelectedClient(e.value);
  };

  return (
    <div className="px-4">
      {/* date component */}
      <div>
        <h1>date component goes here</h1>
      </div>

      {/*  dashboard part goes here*/}
      <div className="my-4 grid grid-cols-4 grid-rows-3 gap-4">
        {/*  no of certificates sold box*/}
        <div className="row-span-2 rounded-md p-4 shadow-md">
          <h1 className="font-medium">No of Certificates Sold</h1>
          {/*<NoOfCertificatesChart />*/}
        </div>

        {/*  client select dropdown */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">Client</h1>
          <Select
            options={clientList}
            name="client"
            id="client"
            onChange={handleChangeClient}
            className="mt-4"
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: "1px solid #A6A6A6",
                height: "42px",
              }),
            }}
          />
        </div>

        {/*  To-do box */}
        <div className="row-span-2 rounded-md p-4 shadow-md">
          <h1 className="font-medium">To Do</h1>
          <div className="mt-4 grid grid-cols-2 grid-rows-2 gap-4">
            <div>
              <h1 className="text-xl font-medium text-black">20</h1>
              <p className="text-sm text-gray-600">Document Review</p>
            </div>
            <div>
              <h1 className="text-xl font-medium text-black">20</h1>
              <p className="text-sm text-gray-600">Lorem Ipsum</p>
            </div>
            <div>
              <h1 className="text-xl font-medium text-black">40</h1>
              <p className="text-sm text-gray-600">Submit Document</p>
            </div>
            <div>
              <h1 className="text-xl font-medium text-black">50</h1>
              <p className="text-sm text-gray-600">Sample topic</p>
            </div>
          </div>
        </div>

        {/*  registry updates box */}
        <div className="row-span-2 rounded-md p-4 shadow-md">
          <h1 className="font-medium">Registry Updates</h1>
          {/*  registry updates list displays here*/}
          <div className="mt-4 h-48 overflow-auto">
            {registryUpdates?.map((registry: any, index: number) => (
              <div key={index} className="border-b border-b-gray-700 p-2">
                <div className="flex items-start gap-2">
                  <div className="rounded-sm bg-gray-300 p-2">image goes here</div>
                  <div>
                    <h1 className="font-medium">{registry.topic}</h1>
                    <h1 className="text-gray-600">{registry.date}</h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/*  No of projects box */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">No of Projects</h1>
          <div className="mt-4 flex items-center justify-between">
            <h1 className="text-2xl font-semibold">
              {selectedClient != null
                ? selectedClientInfo != null
                  ? selectedClientInfo.num_projects
                  : 0
                : dashBoardData?.total_projects}
            </h1>
            <div className="flex items-center gap-2">
              <h1>+ {dashBoardData?.percent_change_24h}%</h1>
              <BiUpArrowAlt size={25} />
            </div>
          </div>
        </div>

        {/*  Total revenue generated box */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">Total Revenue Generated</h1>
          <div className="mt-4 flex items-center justify-between gap-2">
            <h1 className="text-2xl font-semibold">2,00,000</h1>
            <div className="flex items-center gap-2">
              <h1>+ {dashBoardData?.percent_change_24h}%</h1>
              <BiUpArrowAlt size={25} />
            </div>
          </div>
        </div>

        {/*  Total capacity box */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">Total Capacity</h1>
          <h1 className="mt-4 text-2xl font-semibold">
            {selectedClientInfo != null ? selectedClientInfo.total_capacity : 0}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
